import React from "react";
import { graphql } from "gatsby";
import LazyLoad from "react-lazyload";

import { Hero, Offer, Facilities } from "page_components/Home";

import Layout from "components/Layout";
import HubInfo from "components/HubInfo";
import MapSection from "components/MapSection";

const Home = ({
  location,
  pageContext,
  data: { home, offers, facilities, contact },
}) => {
  const hub_info_first = home?.acfHubInfo?.hubinfoContent?.[0];
  const hub_info_second = home?.acfHubInfo?.hubinfoContent?.[1];
  const facilities_data = facilities.nodes
    ?.map(({ acfFacilities: { name, image, position } }) => ({
      text: name,
      icon: image,
      position,
    }))
    .sort((a, b) => a.position - b.position);
  const compare_data = home?.acfCompareItems?.compareItems?.map(
    ({ title, image, text }) => ({ title, text, icon: image })
  );
  const map_data = home?.acfMap?.mapData;

  return (
    <Layout
      seo={{
        title: home.seo.title || home.title,
        description: home.seo.metaDesc || "",
      }}
      location={location}
      translations={pageContext.translations}
      contact_data={contact?.acfContact}
    >
      <Hero
        title={home.acfCompareItems.heroTitle}
        description={home.acfCompareItems.heroText}
        images={home.acfCompareItems.heroSlider}
      />
      {hub_info_first && (
        <HubInfo
          withMarginTop
          title={hub_info_first.title}
          text={hub_info_first.text}
          image={hub_info_first.image.sourceUrl}
        />
      )}
      <Offer
        withFullOfferInfo
        offers={offers?.nodes}
        facilities={facilities_data}
      />
      <Facilities data={compare_data} />
      {hub_info_second && (
        <HubInfo
          withMarginTop
          title={hub_info_second.title}
          text={hub_info_second.text}
          image={hub_info_second.image.sourceUrl}
          button={{
            label: "Learn more",
            href: "/poznaj-przestrzen/",
          }}
        />
      )}
      <LazyLoad>
        <MapSection
          info_variant="inside"
          data={map_data}
          withMainHeader
          withHeaderText
        />
      </LazyLoad>
    </Layout>
  );
};

export default Home;

export const query = graphql`
  query ($id: Int!, $code: WpLanguageCodeEnum!) {
    home: wpPage(databaseId: { eq: $id }) {
      seo {
        title
        metaDesc
      }
      acfHubInfo {
        hubinfoContent {
          title
          text
          image {
            sourceUrl
          }
        }
      }
      acfMap {
        mapData {
          title
          distance
          description
          icon
        }
      }
      acfCompareItems {
        heroTitle
        heroText
        heroSlider {
          image {
            id
            sourceUrl
          }
        }
        compareItems {
          text
          title
          image
        }
      }
      acfContact {
        address
        email
        fieldGroupName
        phone
      }
    }
    offers: allWpOffer(filter: { language: { code: { eq: $code } } }) {
      nodes {
        acfOffer {
          price
          title
          count
          image {
            sourceUrl
          }
        }
      }
    }
    facilities: allWpFacilitie(filter: { language: { code: { eq: $code } } }) {
      nodes {
        acfFacilities {
          name
          image
          position
        }
      }
    }
    contact: wpPage(id: { eq: "cG9zdDoxOTE=" }) {
      acfContact {
        email
        phone
        address
      }
    }
  }
`;
