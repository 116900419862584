import React from "react";
import { navigate } from "gatsby";
import { Formik, Form } from "formik";
import axios from "axios";

import { useLocale } from "context/locale";

import Button from "components/Button";
import Input from "components/Input";
import FormLayout from "components/FormLayout";
import Checkbox from "components/Checkbox";

import heroFormSchema from "libs/validations/heroFormSchema";

const HeroForm = () => {
  const { t } = useLocale();

  const initial_values = {
    full_name: "",
    phone: "",
    email: "",
    persons: "",
    legal: false,
  };

  const onSubmit = async (
    { full_name, phone, email, persons, legal },
    { setSubmitting }
  ) => {
    try {
      let formData = new FormData();

      formData.set("fullName", full_name);
      formData.set("phone", phone);
      formData.set("email", email);
      formData.set("persons", persons);
      formData.set("legal", legal);
      await axios({
        method: "post",
        url: "https://wp.hubwork.pl/wp-json/contact-form-7/v1/contact-forms/5/feedback/",
        data: formData,
        headers: { "Content-Type": "multipart/form-data" },
      });

      navigate("/podziekowanie/");
    } catch (err) {
      console.error(err);
      setSubmitting(false);
    }
  };

  return (
    <FormLayout
      className="hero-form"
      header={
        <>
          <span>{t("Contact us")}</span>
          <p>{t("We will reply as soon as possible")}</p>
        </>
      }
    >
      <Formik
        initialValues={initial_values}
        onSubmit={(values, { setSubmitting }) => {
          onSubmit(values, setSubmitting);
        }}
        validationSchema={heroFormSchema}
      >
        {({ isSubmitting }) => (
          <Form>
            <Input
              name="full_name"
              placeholder={`${t("Full name")}*`}
              disabled={isSubmitting}
            />
            <Input
              type="tel"
              name="phone"
              placeholder={`${t("Phone")}*`}
              disabled={isSubmitting}
            />
            <Input
              type="email"
              name="email"
              placeholder={`${t("E-mail")}*`}
              disabled={isSubmitting}
            />
            <Input
              name="persons"
              placeholder={t("For how many people do you need space?")}
              disabled={isSubmitting}
              type="number"
            />
            <small>* {t("Required fields")}</small>
            <Checkbox
              disabled={isSubmitting}
              label={
                <>
                  *
                  {t(
                    "I give my voluntary consent for my personal data to be processed by Hubpoint Sp. z o.o. Sp. K. You can read more details in our"
                  )}{" "}
                  <a
                    href={t("privacy_policy_link")}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {t("Privacy Policy")}
                  </a>
                  .
                </>
              }
              name="legal"
            />
            <Button type="submit" fullWidth disabled={isSubmitting}>
              {isSubmitting ? `${t("Sending in progress")}...` : t("Send")}
            </Button>
          </Form>
        )}
      </Formik>
    </FormLayout>
  );
};

export default HeroForm;
