import "./styles.scss";
import React from "react";

import { useLocale } from "context/locale";

import FacilitiesTiles from "components/FacilitiesTiles";
import Button from "components/Button";

const Facilities = ({ data }) => {
  const { t } = useLocale();
  return (
    <section className="home-facilities">
      <div className="container">
        <h2>Hubwork vs coworking</h2>
        <span>{t("Why choose Hubwork over coworking?")}</span>
        <FacilitiesTiles data={data} />
        <Button href="/hubwork/">{t("Learn more")}</Button>
      </div>
    </section>
  );
};

export default Facilities;
