import "./styles.scss";
import React from "react";

import HeroForm from "./components/Form";
import Slider from "./components/Slider";

const Hero = ({ title, description, images }) => {
  return (
    <section className="home-hero">
      <Slider images={images} />
      <div className="container">
        <div className="home-hero__content">
          <div className="home-hero__content__text">
            <h1>{title}</h1>
            <p>{description}</p>
          </div>
          <HeroForm />
        </div>
      </div>
    </section>
  );
};

export default Hero;
