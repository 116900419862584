import "./styles.scss";
import React from "react";

import FacilitiesTile from "./components/FacilitiesTile";

const FacilitiesTiles = ({ data }) => {
  return (
    <section className="facilities-tiles">
      {data.map((item, index) => (
        <FacilitiesTile key={index} {...item} />
      ))}
    </section>
  );
};

export default FacilitiesTiles;
