import React, { useState, useRef } from "react";
import Slider from "react-slick";
import LazyLoad from "react-lazyload";

import SliderArrow from "icons/slider-arrow.svg";

const sliderSettings = {
  dots: false,
  autoplay: true,
  infinite: true,
  autoplaySpeed: 5500,
  slidesToShow: 1,
  slidesToScroll: 1,
  lazyLoad: true,
  arrows: false,
  draggable: false,
  pauseOnHover: false,
  useTransform: false,
  responsive: [
    {
      breakpoint: 880,
      settings: {
        slidesToScroll: 0,
      },
    },
  ],
};

const HeroSlider = ({ images }) => {
  const ref = useRef();
  const [images_progress, setImagesProgress] = useState(
    images.map(({ image }, index) => ({
      image,
      status: index === 0 ? "active" : "unactive",
    }))
  );

  const settings = {
    ...sliderSettings,
    afterChange: (i) => {
      const state = images_progress;

      for (let index = 0; index < state.length; index++) {
        if (index > i - 1) {
          state[index].status = "unactive";
        } else {
          state[index].status = "loaded";
        }
      }

      state[i].status = "active";

      setImagesProgress([...state]);
    },
  };

  return (
    <div className="home-hero__slider">
      <Slider {...settings} ref={ref}>
        {images_progress?.map(({ image }, index) => (
          <div key={index}>
            <LazyLoad
              height={200}
              once
              placeholder={
                <div
                  className="home-hero__slider__item"
                  style={{ backgroundColor: "#000" }}
                />
              }
            >
              <div
                className="home-hero__slider__item"
                style={{ backgroundImage: `url(${image.sourceUrl})` }}
              />
            </LazyLoad>
          </div>
        ))}
      </Slider>
      <div className="home-hero__slider__navigation">
        <div className="container">
          <div className="home-hero__slider__navigation__buttons">
            <SliderArrow onClick={() => ref.current.slickPrev()} />
            <SliderArrow onClick={() => ref.current.slickNext()} />
          </div>
          <div className="home-hero__slider__navigation__progress">
            {images_progress?.map(({ status }, index) => (
              <div
                key={index}
                className={`home-hero__slider__navigation__progress__item ${status}`}
                // style={{
                //   background: `linear-gradient(90deg, #25D2C8 ${progress}%, #fff ${progress}%)`,
                // }}
              />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default HeroSlider;
