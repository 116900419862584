import "./styles.scss";
import React from "react";

import OfferTiles from "components/OfferTiles";
import Carousel from "./components/Carousel";
import { useLocale } from "context/locale";

const Offer = ({ withFullOfferInfo, facilities = [], offers = [] }) => {
  const { t } = useLocale();
  return (
    <section className="home-offer">
      <div className="container">
        <h2>{t("Offer")}</h2>
        <Carousel data={facilities} />
        <OfferTiles withFullOfferInfo={withFullOfferInfo} data={offers} />
      </div>
    </section>
  );
};

export default Offer;
