import "./styles.scss";

import React from "react";
import Slider from "react-slick";
import FacilitiesTile from "components/FacilitiesTiles/components/FacilitiesTile";

import SliderArrow from "icons/slider-arrow.svg";

const Carousel = ({ data }) => {
  const settings = {
    dots: false,
    autoplay: false,
    infinite: true,
    autoplaySpeed: 5500,
    slidesToShow: 4,
    arrows: true,
    draggable: false,
    pauseOnHover: false,
    nextArrow: <CustomArrow />,
    prevArrow: <CustomArrow />,
    responsive: [
      {
        breakpoint: 1440,
        settings: {
          slidesToShow: 4,
        },
      },
      {
        breakpoint: 1090,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 820,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 720,
        settings: "unslick",
      },
    ],
  };

  function CustomArrow({ onClick }) {
    return <SliderArrow className="arrow" onClick={onClick} />;
  }

  return (
    <div className="carousel">
      <Slider {...settings}>
        {data.map((item, index) => (
          <FacilitiesTile key={index} {...item} title_variant="small" />
        ))}
      </Slider>
    </div>
  );
};

export default Carousel;
